import React, { useContext, useEffect, useState } from "react";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import { useForm } from "react-hook-form";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { toast } from "react-hot-toast";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";

import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Paper,
  Grid,
  InputAdornment,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LockIcon from "@mui/icons-material/Lock";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import OtpInput from "../ui/v2/components/OtpInput";

export default function ResetPassword() {
  const user: any = useUserSelector();
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useAppLoader();
  const userApi = useUserApi();
  const commonApi = useCommonApi();
  const [loginError, setLoginError] = useState<string | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const [stepIndex, setStepIndex] = useState(0);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");

  const userId = searchParams.get("id");

  const logout = () => {
    userApi.logout(
      {},
      (message: string, resp: any) => {},
      (message: string) => {}
    );
  };

  const StyledForm = styled("form")(({ theme }) => ({
    width: "100%",
    marginTop: theme.spacing(3),
  }));

  const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#F26721",
    "&:hover": {
      backgroundColor: "#D55A1D",
    },
  }));

  const SecureCodeInput = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "start",
    marginBottom: theme.spacing(2),
    gap: "20px",
  }));

  const isPasswordValid =
    password.length >= 8 && /[!@#$%^&*(),.?":{}|<>]/.test(password);

  const onSubmit = (data: any) => {
    showLoader();
    commonApi.post(
      {
        entityName: "ExternalLogon",
        requestName: "ResetPasswordExecuteRequest",
        inputParamters: {
          ResetPassword: {
            ExternalLogonId: userId,
            Code: otp,
            Password: password,
          },
        },
      },
      (message: string, resp: any) => {
        hideLoader();
        console.log("succ message", message);
        toast.success(message);
        setStepIndex(1);
        logout(); //for when user is logged
      },
      (message: string) => {
        hideLoader();
        console.log("error message", message);

        toast.error(message);
      }
    );
  };

  switch (stepIndex) {
    case 0:
      return (
        <Container component="main" maxWidth="xs" sx={{ marginTop: 10 }}>
          <Box sx={{ mb: 3, display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                border: 2,
                borderColor: "#eaeaeb",
                borderRadius: 2,
                padding: 1,
              }}
            >
              <LockIcon fontSize="large" />
            </Box>
          </Box>

          <Typography
            component="h1"
            variant="h5"
            align="center"
            sx={{ fontWeight: 600 }}
          >
            Set new password
          </Typography>

          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            sx={{ mt: 1, fontWeight: 400, fontSize: "15px" }}
          >
            Your new password must be different from previously used passwords.
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                bgcolor: "#F9FAFB",
                borderRadius: 3,
                paddingX: 2,
                paddingY: 2,
                mb: 3,
                mt: 3,
              }}
            >
              <Typography variant="subtitle2" sx={{ mt: 2, mb: 1 }}>
                Secure code
              </Typography>
              <OtpInput onChange={setOtp} length={4} />
            </Box>

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Reset Password"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />

            <Box sx={{ mt: 3, mb: 3 }}>
              <Box sx={{ display: "flex", gap: 1 }}>
                <CheckCircleIcon
                  sx={{ color: password.length >= 8 ? "#06ad14" : "#D0D5DD" }}
                />
                <Typography>Must be at least 8 characters</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 1 }}>
                <CheckCircleIcon
                  sx={{
                    color: /[!@#$%^&*(),.?":{}|<>]/.test(password)
                      ? "#06ad14"
                      : "#D0D5DD",
                  }}
                />
                <Typography>Must contain one special character</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 1 }}>
                <CheckCircleIcon
                  sx={{
                    color:
                      password && password === confirmPassword
                        ? "#06ad14"
                        : "#D0D5DD",
                  }}
                />
                <Typography>Confirm password matches</Typography>
              </Box>
            </Box>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!isPasswordValid || password !== confirmPassword}
            >
              Reset Password
            </Button>
          </form>

          <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
            <Button
              sx={{ mt: 1, color: "#989a9c" }}
              onClick={() => navigate("/entry-type")}
            >
              <ArrowBackIcon /> Back to login
            </Button>
          </Box>
        </Container>
      );
      break;
    case 1:
      return (
        <Container component="main" maxWidth="xs" sx={{ marginTop: 10 }}>
          <Box sx={{ mb: 3, display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                border: 2,
                borderColor: "#eaeaeb",
                borderRadius: 2,
                width: "fit",
                padding: 1,
              }}
            >
              <LockIcon fontSize="large" />
            </Box>
          </Box>
          <Typography
            component="h1"
            variant="h5"
            textAlign={"center"}
            sx={{ fontWeight: 600 }}
          >
            Password reset
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            sx={{ mt: 1, fontWeight: 400, fontSize: "15px" }}
          >
            Your password has been successfully reset. Click below to log in
            magically.{" "}
          </Typography>

          <StyledForm>
            <StyledButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                navigate("/entry-type");
              }}
            >
              Continue
            </StyledButton>
          </StyledForm>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              sx={{ mt: 1, color: "#989a9c" }}
              onClick={() => {
                navigate("/entry-type");
              }}
            >
              <ArrowBackIcon /> Back to login
            </Button>
          </Box>
        </Container>
      );
      break;

    default:
      break;
  }

  return <div>Error step</div>;
}
